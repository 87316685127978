@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Pirata+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Creepster&family=Pirata+One&display=swap');
.App {
  text-align: center;
  /* overflow: hidden; */
  -webkit-tap-highlight-color: transparent;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.App {
  font-family: sans-serif;
  text-align: center;
}

body {
  background-color: black;
  max-width:100%;  /* added */
}

#logo {
  position: fixed;
  display: flex;
  align-items: center;
  width: 1200px;
  height: 1200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#logo text {
  stroke: white;
  font-size: 100px;
  stroke-width: 2px;
  font-family: "Poppins", sans-serif;
  /* stroke-dasharray: 10; */
  /* stroke-dashoffset: 20px; */
  fill: transparent;
  animation: fillColor 0.5 infinite alternate;
  animation: textAnimate 2s infinite alternate;
  /* fill: black; */
  /* animation:fillColor 2s forwards infinite alternate; */
}

@keyframes textAnimate {
  0% {
    stroke-dasharray: 0 50%;
    /* stroke-dashoffset: 230px; */
    fill: transparent;
  }
  100% {
    stroke-dasharray: 50% 0;
    /* fill: black; */
  }
}

@keyframes fillColor {
  0% {
    fill: yellow;
  }
  50% {
    fill: blue;
  }
  100% {
    fill: white;
  }
}

circle,
rect,
line {
  stroke-width: 10px;
  stroke-linecap: round;
  fill: transparent;
}
